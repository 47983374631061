import React, { useState } from 'react';
import "./App.css";
import axios from "axios";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";

function App() {
  return (
    <div className="App">
      <Header />
      <MainSection />
    </div>
  );
}

const Header = () => (
  <header className="header">
    <div className="logo">
      <h2>Veriauth</h2>
    </div>
  </header>
);

const MainSection = () => {
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
    setImagePreview(URL.createObjectURL(uploadedFile));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!file) {
      setError("Please choose a file first!");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    setIsLoading(true);
    setError(null);

    axios
      .post("/verify", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setResult(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("There was an error verifying the file!", error);
        setError(error.response?.data?.error || "An error occurred during verification.");
        setIsLoading(false);
      });
  };

  return (
    <div className="main-section">
      {isLoading ? (
        <div className="loading">
          <p>Processing your request...</p>
        </div>
      ) : result ? (
        <ResultSection result={result} />
      ) : (
        <div className="content-wrapper">
          <div className="left-section">
            <h1>Verify Your Visuals</h1>
            <p className="sub-text">
              In a world flooded with AI-generated visuals, stand out by taking
              ownership of your unique imagery.
            </p>
            <form onSubmit={handleSubmit} className="upload-form">
              <div className="file-input-wrapper">
                <input 
                  type="file" 
                  id="file-upload" 
                  onChange={handleFileChange} 
                  className="file-input"
                />
                <label htmlFor="file-upload" className="file-label">
                  {file ? file.name : "Choose File"}
                </label>
              </div>
              <button type="submit" className="verify-btn">
                Verify
              </button>
            </form>
            {error && <p className="error-message">{error}</p>}
            {imagePreview && (
              <div className="image-preview">
                <h3>Uploaded Image Preview:</h3>
                <img src={imagePreview} alt="Uploaded content preview" />
              </div>
            )}
            <p className="privacy-note">
              We care about your data in our <a href="#privacy">privacy policy</a>.
            </p>
          </div>
          <div className="right-section">
            <img
              src={`${process.env.PUBLIC_URL}/ai.png`}
              alt="Group discussion around a table"
            />
          </div>
        </div>
      )}
    </div>
  );
};

const Map = ({ center, zoom }) => {
  const map = useMap();
  React.useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);
  return null;
};

const ResultSection = ({ result }) => {
  const metadata = result.metadata || {};
  const location = {
    lat: parseFloat(metadata.Latitude),
    lng: parseFloat(metadata.Longitude),
  };

  const showMap = !isNaN(location.lat) && !isNaN(location.lng);

  return (
    <div className="result-section">
      <div className="result-details">
        <h2>{result.status}</h2>
        {result.error ? (
          <p className="error-message">{result.error}</p>
        ) : (
          <>
            <h3>{metadata.title || "Verified File"}</h3>
            <p>
              <strong>Hash: </strong>
              {result.hash || "N/A"}
            </p>
            {Object.entries(metadata).map(([key, value]) => (
              <p key={key}>
                <strong>{key.charAt(0).toUpperCase() + key.slice(1)}: </strong>
                {value}
              </p>
            ))}
            {showMap && (
              <div className="map-container">
                <MapContainer center={[location.lat, location.lng]} zoom={13} scrollWheelZoom={false} style={{ height: '300px', width: '100%' }}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={[location.lat, location.lng]}>
                    <Popup>
                      Lat: {location.lat}, Lng: {location.lng}
                    </Popup>
                  </Marker>
                  <Map center={[location.lat, location.lng]} zoom={13} />
                </MapContainer>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default App;